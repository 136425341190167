<template>
  <div class="Planogram">
    <!-- <v-alert
      border="left"
      outlined
      dense
      v-if="showAlert"
      :color="textColor"
      :icon="iconAlert"
      :dismissible="dismissible"
      class="mt-n10 ml-n3 mb-6"
      max-width="475px"
    >
      {{ importMessage }}
    </v-alert> -->
    <!-- <v-row class="ml-n14 mb-4">
      <v-hover v-slot="{ hover }">
        <v-btn
          @click.stop="importFileDialog = true"
          color="white--text"
          :style="{ 'background-color': hover ? '#f08604' : '#FF8F06' }"
          style="text-transform: unset !important"
          class="ml-11"
          ><v-icon small left>mdi-file-outline</v-icon>Data Files
        </v-btn>
      </v-hover>
    </v-row> -->
    <v-row>
      <!-- Planogram -->
      <v-card
        class="pa-10"
        style="width: 100%; border-radius: 30px"
        elevation="0"
      >
        <div class="planogram">
          <div class="planogram-grid d-flex align-center;" style="">
            <div class="tabs">
              <div class="tab">
                <input type="radio" id="rd3" name="rd" checked="true" />
                <label class="tab-label" for="rd3">{{ $t("drawingArea") }}</label>
                <div class="tab-content">
                  <div id="ShelvesPalette" class="shelves-palette"></div>
                </div>
              </div>
              <div class="tab">
                <input type="radio" id="rd2" name="rd" />
                <label class="tab-label" for="rd2">{{ $t("entryExit") }}</label>
                <div class="tab-content">
                  <div id="EntryExitPalette" class="entry-exit-palette"></div>
                </div>
              </div>
              <div class="tab">
                <input type="radio" id="rd1" name="rd" />
                <label class="tab-label" for="rd1">{{ $t("categories") }}</label>
                <div class="tab-content">
                  <div id="myPaletteSmall" class="categories-palette"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="myDiagramDiv"
            style="flex-grow: 1; height: 500px; border: solid 1px #9b9b9b"
          >
            <v-progress-circular
              indeterminate
              style="margin-left: 300px; margin-top: 200px"
              size="64"
              color="primary"
            ></v-progress-circular>
          </div>
        </div>
      </v-card>
      <!-- !Planogram -->
    </v-row>
    <!-- Buttons -->
    <v-row
      class="d-flex justify-start mt-4"
      v-if="$store.getters['auth/getUser']['type']['id'] == 2"
    >
      <v-hover v-slot="{ hover }">
        <v-btn
          fab
          x-small
          outlined
          :color="hover ? 'primary' : '#424242'"
          class="mx-2"
          style="margin-top: 7px"
          @click.stop="helpDialog = true"
        >
          <v-icon> mdi-help </v-icon>
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <v-btn
          @click.stop="newCategoryDialog = true"
          color="white--text"
          :style="{ 'background-color': hover ? '#002147' : '#535353' }"
          style="text-transform: unset !important"
          class="ml-6 mt-1"
          :disabled="getStoreUpload > 0 ? false : true"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t("addCategory") }}
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <v-btn
          @click.stop="importPlanogramDialog = true"
          color="white--text"
          :style="{ 'background-color': hover ? '#002147' : '#535353' }"
          style="text-transform: unset !important"
          class="ml-6 mt-1"
          :disabled="getStoreUpload > 0 ? false : true"
        >
          <v-icon left> mdi-swap-vertical </v-icon>
         {{ $t("importPlanogram") }}
        </v-btn>
      </v-hover>
      <v-btn
        @click="savePlanogram"
        color="primary"
        class="ml-auto mt-1"
        style="text-transform: unset !important"
        :disabled="getStoreUpload > 0 ? false : true"
        >{{ $t("save") }}</v-btn
      >
    </v-row>
    <!-- !Buttons -->
    <!-- Changes table -->
    <!-- <v-row class="ml-n7 mr-n6 pt-2">
      <v-col>
        <v-card>
          <v-card-title class="justify-center">
            <h5>Changes in planogram</h5>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headersTable"
            :items="changes"
            item-class="color"
            :search="search"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            multi-sort
            :items-per-page="5"
            class="elevation-1"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- !Changes table -->
    <!-- !Products category shelf -->
    <v-row class="ml-n6 pt-6" v-if="getStoreUpload > 0">
      <v-col cols="2" class="pt-8">
        <p class="">{{ $t("category") }}:</p>
      </v-col>
      <v-col cols="3" class="pt-6 ml-n16">
        <v-autocomplete
          v-model="formInputCategory"
          :items="categories"
          item-text="code"
          dense
          @change="changeCategory"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="showShelf"
            color="white--text"
            :style="{ 'background-color': hover ? '#002147' : '#535353' }"
            style="text-transform: unset !important"
            class="mt-2"
            >{{ $t("showShelf") }}</v-btn
          >
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <planogramProducts
        v-if="this.showCategoryShelf"
        :category="this.category"
        :store="this.store"
        style="width: 100%"
      />
    </v-row>
    <!-- !Products category shelf -->
    <!-- Products Dialog -->
    <v-dialog
      v-model="$store.getters['planogram/productsDialog']"
      width="700"
      @click:outside="$store.dispatch('planogram/hideProductsDialog')"
      style="color: black"
    >
      <v-card>
        <v-card-title>
          {{ $store.getters["planogram/name"] }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-btn
            color="primary"
            small
            outlined
            class="mb-4"
            @click="setColorDialog = true"
            style="text-transform: unset !important"
            v-if="$store.getters['auth/getUser']['type']['id'] == 2"
          >
            {{ $t("setColor") }}
            <v-icon right small>mdi-palette</v-icon>
          </v-btn>
          <v-btn
            color="green"
            small
            outlined
            class="mb-4"
            @click="validateCategory"
            style="text-transform: unset !important"
            v-if="$store.getters['auth/getUser']['type']['id'] == 3"
          >
             {{ $t("validate") }}
            <v-icon right small>mdi-check</v-icon>
          </v-btn>
          <v-btn
            color="red"
            small
            outlined
            class="mb-4 ml-8"
            @click="setReasonDialog = true"
            style="text-transform: unset !important"
            v-if="$store.getters['auth/getUser']['type']['id'] == 3"
          >
            {{ $t("notValidate") }}
            <v-icon right small>mdi-close</v-icon>
          </v-btn>
          <br />
          <v-data-table
            :headers="headers"
            :items="$store.getters['planogram/products']"
            :items-per-page="4"
            class="elevation-2"
          >
          </v-data-table>
        </v-card-text>
        <center class="px-6">
          <planogramProductsView
            v-if="$store.getters['planogram/productsDialog']"
            :category="$store.getters['planogram/category']"
            style="width: 100%"
          />
        </center>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$store.dispatch('planogram/hideProductsDialog')"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Products Dialog -->
    <!-- Set Color Dialog -->
    <v-dialog
      v-model="setColorDialog"
      width="550"
      @click:outside="closeSetColorDialog()"
    >
      <v-card>
        <v-card-title>
          {{ $t("setColorOfCategory", {
            1: $store.getters["planogram/name"] 
           }) }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-color-picker
            dot-size="25"
            mode="hexa"
            v-model="colorCategoryComputed"
          ></v-color-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeSetColorDialog()"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="updateCategoryColor"
            style="text-transform: unset !important"
          >
            {{ $t("set") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Set Color Dialog -->
    <!-- Import Planogram Dialog -->
    <v-dialog
      v-model="importPlanogramDialog"
      width="350"
      @click:outside="closeImportPlangoramDialog()"
    >
      <v-card>
        <v-card-title>
          {{ $t("importPlanogram") }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="formInputStore"
            :items="storesArraySorted"
            :label="$t('selectStore') + $t('required')"
            item-text="name"
            color="primary"
            append-icon="mdi-chevron-down"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeImportPlangoramDialog()"
            style="text-transform: unset !important"
            class="mb-4"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2 mb-4"
            @click="importPlanogram"
            style="text-transform: unset !important"
          >
            {{ $t("import") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Import Planogram Dialog -->
    <!-- Not validate reason Dialog -->
    <v-dialog
      v-model="setReasonDialog"
      width="550"
      @click:outside="closeSetReasonDialog()"
    >
      <v-card>
        <v-card-title>
          {{ $t("reasonNotValid", {
            1: $store.getters["planogram/name"] 
           }) }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <a-text-area
            auto-grow
            :label="$t('reason') + $t('required')"
            v-model="$v.reasonInput.$model"
            :validator="$v.reasonInput"
          ></a-text-area>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeSetReasonDialog()"
            style="text-transform: unset !important"
            class="mb-4"
          >
             {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2 mb-4"
            @click="notValidateCategory"
            style="text-transform: unset !important"
          >
             {{ $t("notValidate") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Not validate Dialog -->
    <!-- Help Dialog -->
    <v-dialog
      v-model="helpDialog"
      width="550"
      @click:outside="helpDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("help") }} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("function") }}</th>
                  <th class="text-left">{{ $t("key") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in controls" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.key }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="helpDialog = false"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Help Dialog -->
    <!-- New Category Dialog -->
    <v-dialog
      v-model="newCategoryDialog"
      width="750"
      @click:outside="closeNewCategoryDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("newCategory") }} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="6">
              <a-text-field
                :label="$t('code') + $t('required')"
                v-model="$v.categoryCode.$model"
                :validator="$v.categoryCode"
              >
              </a-text-field>
            </v-col>
            <v-col cols="6" class="ml-n3">
              <a-text-field
                :label="$t('name') + $t('required')"
                v-model="$v.categoryName.$model"
                :validator="$v.categoryName"
              >
              </a-text-field>
            </v-col>
          </v-row>
          <v-col cols="6" class="mt-3 ml-n3">
            <v-btn
              color="primary"
              small
              outlined
              @click="newProductCategoryDialog = true"
              style="text-transform: unset !important"
              >{{ $t("addProduct") }}
              <v-icon right> mdi-plus </v-icon>
            </v-btn>
          </v-col>

          <v-data-table
            :headers="headers"
            :items="this.newCategoryProducts"
            :items-per-page="4"
            class="elevation-2 mt-2"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeNewCategoryDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="createCategory"
            style="text-transform: unset !important"
          >
            {{ $t("create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !New Category Dialog -->
    <!-- New Product Category Dialog -->
    <v-dialog
      v-model="newProductCategoryDialog"
      width="550"
      @click:outside="closeProductCategoryDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("addProduct") }} </v-card-title>
        <v-divider />

        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="6">
              <a-text-field
                :label="$t('name') + $t('required')"
                v-model="$v.inputProductName.$model"
                :validator="$v.inputProductName"
              >
              </a-text-field>
            </v-col>
            <v-col cols="6">
              <a-text-field
                :label="$t('sku') + $t('required')"
                v-model="$v.inputProductSKU.$model"
                :validator="$v.inputProductSKU"
              >
              </a-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeProductCategoryDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="createProductCategory"
            style="text-transform: unset !important"
          >
            {{ $t("add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !New Product Category Dialog -->
    <!-- Import File Dialog -->
    <v-dialog
      v-model="importFileDialog"
      width="350"
      @click:outside="closeImportFileDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{$t('uploadFiles')}}  </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-col>
            <!-- <v-btn color="primary" class="ml-n4" outlined> View Files </v-btn> -->
            <v-file-input
              accept=".xlsx, .xls, .csv"
              class="ml-n4 mt-4"
              v-model="inputProductLocationsFile"
              show-size
              small-chips
              :label="$t('productLocationsFile') + $t('required')"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputProductLocationsFile, 'products')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show">
              {{ message }}
            </p>
          </v-col>
          <v-col>
            <v-file-input
              v-if="getStoreFiles > 0 && this.loadedDiagram"
              accept=".xlsx, .xls, .csv"
              class="ml-n4"
              v-model="inputSalesDataFile"
              show-size
              small-chips
              :label="$t('salesDataFile') + $t('required')"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputSalesDataFile, 'sales')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show2">
              {{ message2 }}
            </p>
          </v-col>
          <p class="red--text mt-2 ml-7">
            {{ checkFile }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="closeImportFileDialog"
            style="text-transform: unset !important"
          >
             {{$t('close')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="importDataFile"
            style="text-transform: unset !important"
          >
            {{$t('import')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Import File Dialog -->
  </div>
</template>
<style scoped lang="scss">
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: #535353;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #dddddd;
  cursor: pointer;
}

.tab-label:hover {
  background: #535353;
  color: white;
}

.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  color: #535353;
  background: white;
}

input:checked + .tab-label {
  background: #535353;
  color: white;
}

input:checked + .tab-label::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content {
  max-height: 100vh;
}

 input[type="radio"] + label:before {
    display: none;
  }
  
  input[type="radio"]:checked + label:before {
    background: none;
  }
/* !Accordion styles */
.planogram {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planogram-grid {
  width: 245px;
  margin-right: 2px;
  background-color: white;
  border: solid 1px #9b9b9b;
}

.shelves-palette {
  width: 245px;
  height: 120px;
}

.entry-exit-palette {
  width: 245px;
  height: 80px;
}

.categories-palette {
  width: 244px;
  height: 320px;
}

.v-dialog {
  overflow-x: hidden;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
import store from "../store";
import ATextField from "../components/ATextField.vue";

import ATextArea from "../components/ATextArea.vue";
import AppError from "@/utils/AppError.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import planogramProducts from "@/components/PlanogramProducts.vue";
import planogramProductsView from "@/components/PlanogramProductsView.vue";

// Default settings to build the planogram.
let AllowTopLevel = false;
let CellSize = new go.Size(50, 50);
let myPaletteSmall = null;
let myDiagram = null;
go.Diagram.licenseKey =
  "73f944e5b46f31b700ca0d2b113f69ee1bb37b3a9e841af95e5342f5ee0c68402a98ed7c58d58fc1d4aa4ffe197bc28d8ec16d2d855c0368b336d2db10e5d1f9b03573b11c0f428df45022979efb2ba7fb2a72fa91e773a78d2d8ff0edac969c09eff6d518981cab2f2d5637562cff4ba5ebda7afa01d346746d9f";

export default {
  name: "settings",

  components: {
    planogramProducts,
    planogramProductsView,
    ATextField,
    ATextArea,
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    categoryName: { required },
    categoryCode: { required },
    reasonInput: { required },
    inputProductName: { required },
    inputProductSKU: { required },
  },

  mounted: function () {
    this.scrollToTop();
  },

  data() {
    return {
      // Data table
      search: null,
      sortBy: ["date"],
      sortDesc: [true],

      showCategoryShelf: false,
      categories: [],
      categoriesPlanogram: [],
      category: null,

      // Form inputs
      formInputCategory: null,

      loadedDiagram: null,
      headersTable: [
        {
          text: this.$t("date"),
          value: "date",
        },
        { text: this.$t("change"), value: "change" },
        { text: this.$t("user"), value: "user" },
      ],
      changes: [],
      headers: [
        {
          text: this.$t("description"),
          align: "start",
          value: "description",
        },
        {
          text: this.$t("sku"),
          value: "SKU",
        },
      ],
      products: [],
      controls: [
        {
          name: "Zoom In",
          key: "CTRL + SCROLL UP",
        },
        {
          name: "Zoom out",
          key: "CTRL + SCROLL DOWN",
        },
        {
          name: "Undo",
          key: "CTRL + Z",
        },
        {
          name: "Redo",
          key: "CTRL + Y",
        },
      ],

      // Dialogs
      helpDialog: false,
      newCategoryDialog: false,
      newProductCategoryDialog: false,
      importFileDialog: false,
      setColorDialog: false,
      setReasonDialog: false,
      importPlanogramDialog: false,

      inputProductLocationsFile: null,
      inputSalesDataFile: null,

      show: false,
      show2: false,
      message2: "",
      message: "",
      checkFile: "",

      importMessage: "",
      textColor: "red",
      iconAlert: "mdi-alert-circle-outline",
      showAlert: false,
      dismissible: false,

      categoryName: null,
      categoryCode: null,
      newCategoryProducts: [],
      inputProductName: null,
      inputProductSKU: null,

      stores: null,
      storesImport: null,
      store: null,
      newColor: null,

      reasonInput: null,
      formInputStore: null,
    };
  },

  computed: {
    // Computed property to get and set the color of each product
    colorCategoryComputed: {
      get: function () {
        return this.$store.getters["planogram/category"].color;
      },
      set: function (newValue) {
        this.newColor = newValue;
      },
    },

    getStoreUpload() {
      return this.categories ? this.categories.length : null;
    },
    getStoreFiles() {
      return this.store ? this.store.files.length : null;
    },

    storesArraySorted: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      if (this.storesImport) {
        return this.storesImport.concat().sort(compare);
      } else {
        return this.storesImport;
      }
    },
  },

  methods: {
    /**
     * Get all the planogram changes.
     * @public
     */
    getPlanogramChanges() {
      this.changes = [
        {
          date: "14-04-2023",
          change: "Category AAA was deleted",
          user: "andrehpereira@sapo.pt",
          color: "red",
        },
        {
          date: "12-04-2023",
          change: "Category BBBB was added at position [-40 , 150]",
          user: "teste@teste.pt",
          color: "green",
        },
        {
          date: "10-04-2023",
          change:
            "Category AHSB was moved from position [-50, 50] to [-50, 100]",
          user: "testeteste@sas.pt",
          color: "blue",
        },
      ];
    },
    /**
     * Get the updated planogram after saving it, and get the alert message.
     * @public
     */
    getUpdatedPlanogramOnly() {
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data.planogram;
        })
        .then(() => {
          this.getMessage();
        })
        .catch((err) => {
          AppError.handle(err);
        });
    },
    /**
     * Get the store .
     * @param {store} store - the store.
     * @param {load} load - variable to check if it is on load stores.
     * @public
     */
    getStore(store, load) {
      return new Promise((resolve, reject) => {
        let url = this.$config.api.route.storesAction;
        url = url.replace(":id", store.id);

        this.$api({
          url: url,
          method: "GET",
        })
          .then((resp) => {
            this.store = resp.data;
            localStorage.removeItem("store");
            localStorage.setItem("store", JSON.stringify(this.store));
            resolve();
          })
          .catch((err) => {
            AppError.handle(err);
            reject(err);
          })
          .finally(() => {
            if (!load) {
              this.getMessage();
            }
          });
      });
    },
    /**
     * Get the alert message.
     * @public
     */
    getMessage() {
      if (this.store) {
        if (!this.store.files.length > 0) {
          this.showAlert = true;
          this.importMessage =
            "Import your locations file to start creating the planogram";
          this.textColor = "red";
          this.iconAlert = "mdi-alert-circle-outline";
        }
        if (this.store.files.length > 0 && !this.store.has_upload) {
          this.showAlert = true;
          this.importMessage =
            "Your file is being processed. Please come back in 12h.";
          this.textColor = "#0067FF";
          this.iconAlert = "mdi-alert-circle-outline";
        }
        if (
          this.loadedDiagram &&
          this.store.has_upload &&
          this.store.files.length < 2
        ) {
          this.showAlert = true;
          this.importMessage = "You can now upload your sales file";
          this.textColor = "#0067FF";
        }
        if (
          this.store.files.length > 0 &&
          this.store.has_upload &&
          !this.loadedDiagram
        ) {
          this.showAlert = true;
          this.importMessage = "Draw your planogram to import your sales file";
          this.textColor = "#FF8F06";
          this.iconAlert = "mdi-alert-circle-outline";
        }
        if (
          this.loadedDiagram &&
          this.store.has_upload &&
          this.store.files.length == 2
        ) {
          this.showAlert = false;
        }
      }
    },
    /**
     * Auto scroll page to the top.
     * @public
     */
    scrollToTop() {
      window.scrollTo(0, 0);
      this.getPlanogramChanges();
      this.loadStores();
    },
    /**
     * Update category color.
     * @public
     */
    updateCategoryColor() {
      if (this.newColor) {
        var node = myPaletteSmall.model.findNodeDataForKey(
          this.$store.getters["planogram/category"].key
        );
        if (node) {
          this.$store.dispatch("planogram/removeCategory");
          myPaletteSmall.model.setDataProperty(node, "color", this.newColor);
          this.$store.dispatch("planogram/addCategory", node);
        } else {
          var nodeS = myDiagram.model.findNodeDataForKey(
            this.$store.getters["planogram/category"].key
          );
          this.$store.dispatch("planogram/removeCategory");
          myDiagram.model.setDataProperty(nodeS, "color", this.newColor);
          this.$store.dispatch("planogram/addCategory", nodeS);
        }
      }

      let color = this.$store.getters["planogram/category"].color;

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categoriesAction;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.$store.getters["planogram/category"].key);
      this.$api({
        url: url,
        data: {
          color: color,
        },
        method: "PUT",
      })
        .then(() => {
          this.closeSetColorDialog();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t("categoryColorUpdated")
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    closeSetColorDialog() {
      this.setColorDialog = false;
      this.newColor = null;
    },
    closeSetReasonDialog() {
      this.setReasonDialog = false;
      this.reasonInput = null;
      this.$v.reasonInput.$reset();
    },
    closeImportPlangoramDialog() {
      this.importPlanogramDialog = false;
      this.formInputStore;
    },
    importPlanogram() {
      let store = this.storesImport.find(
        (store) => store.name === this.formInputStore
      );
      if (!store) {
        this.$store.dispatch(
          "alert/showWarning",
          this.$t("chooseStoreImport")
        );
        return;
      }
    },
    validateCategory() {
      this.$store.dispatch(
        "alert/showSuccess",
        this.$t("categoryValidated")
      );
    },
    notValidateCategory() {
      this.$v.reasonInput.$touch();
      if (this.$v.reasonInput.$invalid) {
        return;
      }
      this.$store.dispatch(
        "alert/showSuccess",
        this.$t("categoryNotValidated")
      );
      this.closeSetReasonDialog();
    },
    /**
     * Create/save the planogram.
     * @public
     */
    savePlanogram() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        data: {
          name: "Original Planogram",
          planogram: myDiagram.model.toJson(),
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch(
            "alert/showSuccess",
             this.$t("savePlanogram")
          );
          this.getUpdatedPlanogramOnly();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Import files.
     * @public
     */
    importDataFile() {
      if (!this.inputProductLocationsFile && !this.inputSalesDataFile) {
        this.checkFile = "Please import a file.";
        return;
      }
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.importGenericFiles;
      url = url.replace(":id", this.store.id);

      const formData = new FormData();
      if (this.inputProductLocationsFile) {
        formData.append("type", "generic_product_location");
        formData.append("file", this.inputProductLocationsFile);
      }

      if (this.inputSalesDataFile) {
        formData.append("type", "generic_sales_data");
        formData.append("file", this.inputSalesDataFile);
      }

      this.$api({
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
      })
        .then(() => {
          this.closeImportFileDialog();
          this.$store.dispatch(
            "alert/showSuccess",
            "Files imported successfully."
          );
          this.getStore(this.store);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close import files dialog.
     * @public
     */
    closeImportFileDialog() {
      this.inputProductLocationsFile = null;
      this.inputSalesDataFile = null;
      this.importFileDialog = false;
      this.checkFile = "";
    },
    /**
     * Handle file input change.
     * @param {file} inputFile - the file.
     * @param {string} type - the type of the file.
     * @public
     */
    inputChanged(inputFile, type) {
      if (inputFile) {
        if (
          inputFile.type.match(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) ||
          inputFile.type.match(".csv")
        ) {
          if (type == "products") {
            this.show = false;
          } else {
            this.show2 = false;
          }
        } else {
          if (type == "products") {
            this.show = true;
            this.message = this.$t("excelFile");
          } else {
            this.show2 = true;
            this.message2 = this.$t("excelFile");
          }
        }
      } else {
        if (type == "products") {
          this.show = false;
        } else {
          this.show2 = false;
        }
      }
    },

    /**
     * Get the users stores.
     * @public
     */
    loadStores() {
      var user = this.$store.getters["auth/getUser"];
      if (user.type.id != 1) {
        let url = this.$config.api.route.usersStores;
        url = url.replace(":id", user.id);
        this.$api({
          url: url,
          method: "GET",
        })
          .then((resp) => {
            this.stores = resp.data;
            var lastStore = localStorage.getItem("store");
            if (lastStore) {
              return this.getStore(JSON.parse(lastStore), true).then(() => {
                this.getLoadedPlanogram();
              });
            } else {
              this.store = this.stores[0];
              localStorage.setItem("store", JSON.stringify(this.store));
              this.getLoadedPlanogram();
              return Promise.resolve();
            }
          })
          .then(() => {
            this.storesImport = [...this.stores];
            this.storesImport.forEach((store, index) => {
              if (store.code === this.store.code) {
                this.storesImport.splice(index, 1);
              }
            });
          })
          .catch((err) => {
            AppError.handle(err);
          })
          .finally(() => {});
      }
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedPlanogram() {
      //this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data.planogram;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.getCategories();
        });
    },
    /**
     * Create category.
     * @public
     */
    createCategory() {
      this.$v.categoryName.$touch();
      this.$v.categoryCode.$touch();
      if (this.$v.categoryName.$invalid || this.$v.categoryCode.$invalid) {
        return;
      }
      if (this.newCategoryProducts.length == 0) {
        this.$store.dispatch(
          "alert/showWarning",
          this.$t("newCategoryProductWarning")
        );
        return;
      }

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);

      this.$api({
        url: url,
        data: {
          code: this.categoryCode,
          category: this.categoryName,
          products: this.newCategoryProducts,
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t("categoryCreated")
          );
          myPaletteSmall.model.addNodeData({
            key: this.categoryCode,
            name: this.categoryName,
            color: "#FFFFFF",
            size: "150 50",
            products: this.newCategoryProducts,
            category: "normal",
          });
          this.getNewCategories();
          this.closeNewCategoryDialog();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close new category dialog.
     * @public
     */
    closeNewCategoryDialog() {
      this.$v.categoryName.$reset();
      this.$v.categoryCode.$reset();
      this.categoryCode = null;
      this.categoryName = null;
      this.newCategoryDialog = false;
      this.newCategoryProducts = [];
    },
    /**
     * Create products in new category.
     * @public
     */
    createProductCategory() {
      this.$v.inputProductName.$touch();
      this.$v.inputProductSKU.$touch();
      if (
        this.$v.inputProductName.$invalid ||
        this.$v.inputProductSKU.$invalid
      ) {
        return;
      }

      this.newCategoryProducts.push({
        description: this.inputProductName,
        SKU: this.inputProductSKU,
      });

      this.closeProductCategoryDialog();
    },
    /**
     * Close product category dialog.
     * @public
     */
    closeProductCategoryDialog() {
      this.$v.inputProductName.$reset();
      this.$v.inputProductSKU.$reset();
      this.inputProductName = null;
      this.inputProductSKU = null;
      this.newProductCategoryDialog = false;
    },
    /**
     * Get the selected category.
     * @public
     */
    changeCategory() {
      let selectedCategory = this.categories.find(
        (category) => category.code === this.formInputCategory
      );

      this.category = selectedCategory;
      this.showCategoryShelf = false;
    },
    /**
     * Set the shelf planogram.
     * @public
     */
    showShelf() {
      this.showCategoryShelf = true;
    },
    getNewCategories() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.categories = resp.data;
          this.categoriesPlanogram = [...this.categories];
          this.formInputCategory = this.categories[0].code;
          this.category = this.categories[0];
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get categories.
     * @public
     */
    getCategories() {
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.categories = resp.data;
          this.categoriesPlanogram = [...this.categories];
          if (this.categories.length > 0) {
            this.formInputCategory = this.categories[0].code;
            this.category = this.categories[0];
          }
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.getMessage();
          this.buildPlanogram();
        })
        .finally(() => {
          //this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Build planogram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(go.Diagram, "myDiagramDiv", {
        grid: $(
          go.Panel,
          "Grid",
          { gridCellSize: CellSize, background: "white" },
          $(go.Shape, "LineH", { stroke: "lightgray" }),
          $(go.Shape, "LineV", { stroke: "lightgray" })
        ),
        "draggingTool.isGridSnapEnabled": true,
        "draggingTool.gridSnapCellSpot": go.Spot.Center,
        "resizingTool.isGridSnapEnabled": true,
        "animationManager.isEnabled": false,
        "undoManager.isEnabled": true,
        isReadOnly:
          this.$store.getters["auth/getUser"]["type"]["id"] === 2
            ? false
            : true,
      });

      if (this.loadedDiagram) {
        // Getting data from json
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);

        myDiagram.model.nodeDataArray.forEach((nodeS) => {
          this.categoriesPlanogram.forEach((category, index) => {
            if (nodeS.key === category.code) {
              this.categoriesPlanogram.splice(index, 1);
            }
          });
        });
      }

      myDiagram.commandHandler.doKeyDown = function () {
        // must be a function, not an arrow =>
        var e = this.diagram.lastInput;
        var key = e.key;
        var selectedNode = myDiagram.selection.first();
        var node = selectedNode.data;
        if (
          (key === "Del" && node.products) ||
          (key === "Backspace" && node.products)
        )
          myPaletteSmall.model.addNodeData({
            key: selectedNode.data.key,
            name: selectedNode.data.name,
            color: selectedNode.data.color,
            size: "150 50",
            products: selectedNode.data.products,
            category: "normal",
          });
        // call base method with no arguments (default functionality)
        go.CommandHandler.prototype.doKeyDown.call(this);
      };

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          doubleClick: function (e, node) {
            // Verify if a node is entry or exit and don't show the modal, otherwise show modal with products.
            if (node.data.key == "Entry" || node.data.key == "Exit") {
              return;
            } else {
              if (node.data.key === store.getters["planogram/category"].key) {
                node.data.color = store.getters["planogram/category"].color;
              }
              store.dispatch("planogram/showProductsDialog");
              store.dispatch("planogram/addCategory", node.data);
            }
          },

          mouseEnter: function (e, node) {
            if (store.getters["planogram/category"]) {
              if (node.data.key === store.getters["planogram/category"].key) {
                myDiagram.model.nodeDataArray.forEach(function (nodeS) {
                  if (node.key === nodeS.key) {
                    myDiagram.model.setDataProperty(
                      nodeS,
                      "color",
                      store.getters["planogram/category"].color
                    );
                  }
                });
              }
            }
          },

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "#9b9b9b",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: "bold 14px sans-serif" },
          new go.Binding("text", "key")
        )
      );
      // end Node

      // Heatmap Template used for heatmap nodes.
      var heatMapTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "HEATMAP",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("heatmap", heatMapTemplate);
      templmap.add("", myDiagram.nodeTemplate);

      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();

            var diagram = grp.diagram;
            var draggedParts = diagram.selection;

            // Check if the dropped parts contain nodes
            var droppedNodes = draggedParts.filter(function (part) {
              return part instanceof go.Node;
            });

            if (droppedNodes.count > 0) {
              var nodeToRemove = myPaletteSmall.model.findNodeDataForKey(
                droppedNodes.first().data.key
              );
              myPaletteSmall.model.removeNodeData(nodeToRemove);
            }
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );

      // Decide what kinds of Parts can be added to a Group
      myDiagram.commandHandler.memberValidation = function (grp, node) {
        // cannot add Groups to Groups
        if (grp instanceof go.Group && node instanceof go.Group) return false;
        return true;
      };

      // What to do when a drag-drop occurs in the Diagram's background
      myDiagram.mouseDragOver = function (e) {
        if (!AllowTopLevel) {
          // OK to drop a group anywhere or any Node that is a member of a dragged Group
          var tool = e.diagram.toolManager.draggingTool;
          if (
            !tool.draggingParts.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel &&
                  tool.draggingParts.contains(p.containingGroup))
              );
            })
          ) {
            e.diagram.currentCursor = "not-allowed";
          } else {
            e.diagram.currentCursor = "";
          }
        }
      };

      myDiagram.mouseDrop = function (e) {
        if (AllowTopLevel) {
          // When the selection is dropped in the diagram's background,
          // Make sure the selected Parts no longer belong to any Group
          if (
            !e.diagram.commandHandler.addTopLevelParts(
              e.diagram.selection,
              true
            )
          ) {
            e.diagram.currentTool.doCancel();
          }
        } else {
          // Disallow dropping any regular nodes onto the background, but allow dropping "racks",
          // Including any selected member nodes
          if (
            !e.diagram.selection.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel && p.containingGroup.isSelected)
              );
            })
          ) {
            e.diagram.currentTool.doCancel();
          }
        }
      };

      // Create array with category and its products to create nodes.
      var categories = [];
      if (this.categoriesPlanogram) {
        this.categoriesPlanogram.map((category) => {
          var categoryProducts = [];

          if (category.products != null) {
            category.products.map((product) => {
              categoryProducts.push(product);
            });
          }

          var newCategory = {
            key: category.code,
            name: category.name,
            color: category.color,
            size: "150 50",
            products: categoryProducts,
            category: "normal",
          };

          myDiagram.model.nodeDataArray.forEach(function (node) {
            if (newCategory.key === node.key) {
              myDiagram.model.setDataProperty(node, "color", newCategory.color);
            }
          });

          categories.push(newCategory);
        });
      }

      // Compare function to sort alphabetically the categories.
      function compare(a, b) {
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      }

      // Sort categories
      categories.sort(compare);

      myPaletteSmall = $(go.Palette, "myPaletteSmall", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      myPaletteSmall.model = new go.GraphLinksModel(categories);

      let EntryExitPalette = $(go.Palette, "EntryExitPalette", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      EntryExitPalette.model = new go.GraphLinksModel([
        { key: this.$t("entry"), color: "#C5DDAF", size: "50 50", category: "normal" },
        { key: this.$t("exit"), color: "#DDBAB9", size: "50 50", category: "normal" },
      ]);

      let ShelvesPalette = $(go.Palette, "ShelvesPalette", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      ShelvesPalette.model = new go.GraphLinksModel([
        { key: "Shelf", isGroup: true, size: "100 100", category: "normal" },
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: none !important;
}
</style>
