<template>
  <div>
    <!-- Planogram -->
    <v-card
      class="pa-10"
      style="width: 100%; border-radius: 30px"
      elevation="0"
    >
      <div class="planogram-products pb-12">
        <div class="planogram-grid-products d-flex align-center;">
          <div class="tabs-products">
            <div class="tab-products">
              <input
                type="radio"
                :id="this.category.code + 'rd2'"
                name="rd"
                checked="true"
              />
              <label
                class="tab-label-products"
                :for="this.category.code + 'rd2'"
                >{{ $t("drawingArea") }}</label
              >
              <div class="tab-content-products">
                <div
                  :id="this.category.code + 'ShelvesPalette'"
                  class="shelves-palette-products"
                ></div>
              </div>
            </div>
            <div class="tab-products">
              <input type="radio" :id="this.category.code + 'rd1'" name="rd" />
              <label
                class="tab-label-products"
                :for="this.category.code + 'rd1'"
                >{{ $t("skus") }}</label
              >
              <div class="tab-content-products">
                <div
                  :id="this.category.code + 'myPaletteSmall'"
                  class="products-palette"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div
          :id="this.category.code"
          style="flex-grow: 1; height: 500px; border: solid 1px #9b9b9b"
        >
          <v-progress-circular
            indeterminate
            style="margin-left: 250px; margin-top: 200px"
            size="64"
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-card>
    <!-- !Planogram -->
    <!-- Buttons -->
    <v-row
      class="d-flex justify-start mt-4 mr-0"
      v-if="$store.getters['auth/getUser']['type']['id'] != 3"
    >
      <v-hover v-slot="{ hover }">
        <v-btn
          fab
          x-small
          outlined
          :color="hover ? 'primary' : '#535353'"
          class="mr-2"
          style="margin-left: 20px; margin-top: -5px"
          @click.stop="helpDialog = true"
        >
          <v-icon> mdi-help </v-icon>
        </v-btn>
      </v-hover>
      <v-hover v-slot="{ hover }">
        <v-btn
          @click.stop="newProductDialog = true"
          color="white--text"
          :style="{
            'background-color': hover ? '#002147' : '#535353',
          }"
          style="text-transform: unset !important"
          class="ml-6 mt-n2"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t("addProduct") }}</v-btn
        >
      </v-hover>
      <v-btn
        @click="createShelf"
        color="primary"
        class="ml-auto mt-n2"
        style="text-transform: unset !important"
        >{{ $t("save") }}</v-btn
      >
    </v-row>
    <!-- !Buttons -->
    <!-- Product Dialog -->
    <v-dialog
      v-model="$store.getters['planogram/depthDialog']"
      width="700"
      @click:outside="closeDepthDialog"
    >
      <v-card>
        <v-card-title>
          {{ $t("product") }}: {{ $store.getters["planogram/product"].name }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <a-text-field :label="$t('depth')" v-model="nodeDepth">
              </a-text-field>
              <a-text-field
                :label="$t('width')"
                class="mt-4"
                v-model="nodeWidth"
              >
              </a-text-field>
            </v-col>
            <v-col>
              <v-color-picker
                dot-size="25"
                mode="hexa"
                v-model="colorProductComputed"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeDepthDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="addWidthDepthColor"
            style="text-transform: unset !important"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Product Dialog -->
    <!-- Help Dialog -->
    <v-dialog
      v-model="helpDialog"
      width="550"
      @click:outside="helpDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5"> {{ $t("help") }} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-simple-table class="ml-n4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("function") }}</th>
                  <th class="text-left">{{ $t("key") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in controls" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.key }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="helpDialog = false"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Help Dialog -->
    <!-- New Product Dialog -->
    <v-dialog
      v-model="newProductDialog"
      width="550"
      @click:outside="closeProductDialog"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{
            $t("addNewProductToCategory", {
              1: this.category.code,
            })
          }}
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-row>
            <v-col cols="6">
              <a-text-field
                :label="$t('name') + $t('required')"
                v-model="$v.inputProductName.$model"
                :validator="$v.inputProductName"
              >
              </a-text-field>
            </v-col>
            <v-col cols="6">
              <a-text-field
                :label="$t('sku') + $t('required')"
                v-model="$v.inputProductSKU.$model"
                :validator="$v.inputProductSKU"
              >
              </a-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeProductDialog"
            style="text-transform: unset !important"
          >
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-2"
            @click="createProduct"
            style="text-transform: unset !important"
          >
            {{ $t("add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !New Product Dialog -->
  </div>
</template>
<style scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs-products {
  overflow: hidden;
}

.tab-products {
  width: 100%;
  color: #535353;
  overflow: hidden;
}

.tab-label-products {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #dddddd;
  cursor: pointer;
}

.tab-label-products:hover {
  background: #535353;
  color: white;
}

.tab-label-products::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content-products {
  max-height: 0;
  color: #535353;
  background: white;
}

input:checked + .tab-label-products {
  background: #535353;
  color: white;
}

input:checked + .tab-label-products::after {
  transform: rotate(90deg);
}

input:checked ~ .tab-content-products {
  max-height: 100vh;
}

input[type="radio"] + label:before {
  display: none;
}

input[type="radio"]:checked + label:before {
  background: none;
}

/* !Accordion styles */

.planogram-products {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planogram-grid-products {
  width: 343px;
  margin-right: 2px;
  background-color: white;
  border: solid 1px #9b9b9b;
}

.shelves-palette-products {
  width: 343px;
  height: 120px;
}

.products-palette {
  width: 343px;
  height: 418px;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
import AppError from "@/utils/AppError.js";
import ATextField from "../components/ATextField.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import store from "../store";

let AllowTopLevel = false;
let CellSize = new go.Size(50, 50);
let myPaletteSmall = null;
let myDiagram = null;
go.Diagram.licenseKey =
  "73f944e5b46f31b700ca0d2b113f69ee1bb37b3a9e841af95e5342f5ee0c68402a98ed7c58d58fc1d4aa4ffe197bc28d8ec16d2d855c0368b336d2db10e5d1f9b03573b11c0f428df45022979efb2ba7fb2a72fa91e773a78d2d8ff0edac969c09eff6d518981cab2f2d5637562cff4ba5ebda7afa01d346746d9f";

export default {
  name: "planogram-products",

  components: {
    ATextField,
  },

  props: {
    category: {
      type: Object,
      required: true,
    },
    store: {
      type: Object,
      default: null,
    },
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    inputProductName: { required },
    inputProductSKU: { required },
    inputAisle: { required },
    inputSide: { required },
  },

  mounted: function () {
    this.getCategories();
  },

  data() {
    return {
      loadedDiagram: null,
      categories: null,
      controls: [
        {
          name: "Zoom In",
          key: "CTRL + SCROLL UP",
        },
        {
          name: "Zoom out",
          key: "CTRL + SCROLL DOWN",
        },
        {
          name: "Undo",
          key: "CTRL + Z",
        },
        {
          name: "Redo",
          key: "CTRL + Y",
        },
      ],
      // Dialogs
      helpDialog: false,
      newProductDialog: false,

      inputDepth: null,
      inputWidth: null,
      newColor: null,

      inputProductName: null,
      inputProductSKU: null,
      inputAisle: null,
      inputSide: null,
    };
  },

  computed: {
    // Computed property to get and set the color of each product
    colorProductComputed: {
      get: function () {
        return this.$store.getters["planogram/product"].color;
      },
      set: function (newValue) {
        this.newColor = newValue;
      },
    },
    // Computed property to get and set the depth of each product
    nodeDepth: {
      get: function () {
        var depth = this.$store.getters["planogram/product"].depth;
        return String(depth);
      },
      set: function (newValue) {
        this.$store.dispatch("planogram/addDepth", newValue);
      },
    },
    // Computed property to get and set the width of each product
    nodeWidth: {
      get: function () {
        var width = this.$store.getters["planogram/product"].width;
        return String(width);
      },
      set: function (newValue) {
        this.$store.dispatch("planogram/addWidth", newValue);
      },
    },
  },

  methods: {
    /**
     * Get categories.
     * TODO: Criar endpoint para retornar os produtos de uma determinada categoria.
     * @public
     */
    getCategories() {
      //this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categories;
      url = url.replace(":id", this.store.id);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.categories = resp.data;
          this.categories.map((category) => {
            if (category.code === this.category.code) {
              this.newCategory = category;
            }
          });
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.getLoadedDiagram();
        });
    },
    /**
     * Create new product.
     * @public
     */
    createProduct() {
      this.$v.inputProductName.$touch();
      this.$v.inputProductSKU.$touch();
      //this.$v.inputAisle.$touch();
      //this.$v.inputSide.$touch();
      if (
        this.$v.inputProductName.$invalid ||
        this.$v.inputProductSKU.$invalid
        // this.$v.inputAisle.$invalid ||
        //this.$v.inputSide.$invalid
      ) {
        return;
      }

      var nodes = [];

      myPaletteSmall.model.nodeDataArray.forEach(function (node) {
        nodes.push(node.key);
      });

      var isRepeated = false;
      nodes.forEach((node) => {
        if (node === this.inputProductSKU) {
          isRepeated = true;
        }
      });

      if (isRepeated === true) {
        this.$store.dispatch("alert/showError", this.$t("skuDuplicated"));
        return;
      }

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.newCategoryShelfProduct;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.category.code);
      this.$api({
        url: url,
        data: {
          description: this.inputProductName,
          sku: this.inputProductSKU,
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch("alert/showSuccess", this.$t("productCreated"));
          myPaletteSmall.model.addNodeData({
            key: this.inputProductSKU,
            name: this.inputProductName,
            color: "#FFFFFF",
            size: "150 50",
            category: "normal",
            depth: 0,
            width: 0,
          });
          this.closeProductDialog();
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close new product dialog.
     * @public
     */
    closeProductDialog() {
      this.$v.inputProductName.$reset();
      this.$v.inputProductSKU.$reset();
      this.$v.inputAisle.$reset();
      this.$v.inputSide.$reset();
      this.inputProductName = null;
      this.inputProductSKU = null;
      this.inputAisle = null;
      this.inputSide = null;
      this.newProductDialog = false;
    },
    /**
     * Update product's depth, width and color.
     * @public
     */
    addWidthDepthColor() {
      if (this.newColor) {
        var node = myPaletteSmall.model.findNodeDataForKey(
          this.$store.getters["planogram/product"].key
        );
        if (node) {
          this.$store.dispatch("planogram/removeProduct");
          myPaletteSmall.model.setDataProperty(node, "color", this.newColor);
          this.$store.dispatch("planogram/addProduct", node);
        } else {
          var nodeS = myDiagram.model.findNodeDataForKey(
            this.$store.getters["planogram/product"].key
          );
          this.$store.dispatch("planogram/removeProduct");
          myDiagram.model.setDataProperty(nodeS, "color", this.newColor);
          this.$store.dispatch("planogram/addProduct", nodeS);
        }
      }

      let depth = this.$store.getters["planogram/product"].depth;
      let width = this.$store.getters["planogram/product"].width;
      let color = this.$store.getters["planogram/product"].color;

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.categoryShelfProduct;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.category.code);
      url = url.replace(
        ":product_id",
        this.$store.getters["planogram/product"].key
      );
      this.$api({
        url: url,
        data: {
          depth: depth,
          width: width,
          color: color,
        },
        method: "PUT",
      })
        .then(() => {
          this.closeDepthDialog();
          this.$store.dispatch("alert/showSuccess", this.$t("productEdited"));
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Close product information dialog.
     * @public
     */
    closeDepthDialog() {
      this.inputDepth = null;
      this.inputWidth = null;
      this.newColor = null;
      this.$store.dispatch("planogram/hideDepthDialog");
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedDiagram() {
      let url = this.$config.api.route.productsShelf;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.category.code);
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedDiagram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.buildPlanogram();
          //this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Create category products planogram.
     * @public
     */
    createShelf() {
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.productsShelf;
      url = url.replace(":id", this.store.id);
      url = url.replace(":code", this.category.code);
      this.$api({
        url: url,
        data: {
          products_shelf: myDiagram.model.toJson(),
        },
        method: "POST",
      })
        .then(() => {
          this.$store.dispatch("alert/showSuccess", this.$t("shelfCreated"));
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Build diagram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(go.Diagram, this.category.code, {
        grid: $(
          go.Panel,
          "Grid",
          { gridCellSize: CellSize, background: "white" },
          $(go.Shape, "LineH", { stroke: "lightgray" }),
          $(go.Shape, "LineV", { stroke: "lightgray" })
        ),
        "draggingTool.isGridSnapEnabled": true,
        "draggingTool.gridSnapCellSpot": go.Spot.Center,
        "resizingTool.isGridSnapEnabled": true,
        "animationManager.isEnabled": false,
        "undoManager.isEnabled": true,
        isReadOnly:
          this.$store.getters["auth/getUser"]["type"]["id"] === 2
            ? false
            : true,
      });

      if (this.loadedDiagram) {
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);

        myDiagram.model.nodeDataArray.forEach((nodeS) => {
          this.newCategory.products.forEach((product, index) => {
            if (nodeS.key === product.SKU) {
              this.newCategory.products.splice(index, 1);
            }
          });
        });
      }

      myDiagram.commandHandler.doKeyDown = function () {
        // must be a function, not an arrow =>
        var e = this.diagram.lastInput;
        var key = e.key;
        var selectedNode = myDiagram.selection.first();
        var node = selectedNode.data;
        if (
          (key === "Del" && node.products) ||
          (key === "Backspace" && node.products)
        )
          myPaletteSmall.model.addNodeData({
            key: selectedNode.data.key,
            name: selectedNode.data.name,
            color: selectedNode.data.color,
            size: "150 50",
            category: "normal",
            depth: selectedNode.data.depth,
            width: selectedNode.data.width,
          });
        // call base method with no arguments (default functionality)
        go.CommandHandler.prototype.doKeyDown.call(this);
      };

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          doubleClick: function (e, node) {
            // Verify if a node is entry or exit and don't show the modal, otherwise show modal with products.
            if (node.data.key == "Entry" || node.data.key == "Exit") {
              return;
            } else {
              if (node.data.key === store.getters["planogram/product"].key) {
                node.data.width = store.getters["planogram/product"].width;
                node.data.depth = store.getters["planogram/product"].depth;
                node.data.color = store.getters["planogram/product"].color;
              }
              store.dispatch("planogram/showDepthDialog");
              store.dispatch("planogram/addProduct", node.data);
            }
          },

          mouseEnter: function (e, node) {
            if (store.getters["planogram/category"]) {
              if (node.data.key === store.getters["planogram/category"].key) {
                myDiagram.model.nodeDataArray.forEach(function (nodeS) {
                  if (node.key === nodeS.key) {
                    myDiagram.model.setDataProperty(
                      nodeS,
                      "color",
                      store.getters["planogram/category"].color
                    );
                  }
                });
              }
            }
            var nodeContextMenu = $(
              go.Adornment,
              "Spot",
              { background: "transparent" },
              $(go.Placeholder),
              $(go.Shape, {
                figure: "RoundedRectangle",
                fill: node.data.color,
                height: 40,
                width: 320,
              }),
              $(
                go.Panel,
                "Horizontal",
                { margin: 8 },

                $(
                  go.TextBlock,
                  { text: node.data.name },
                  {
                    stroke: "black",
                    height: 30,
                    alignment: go.Spot.Center,
                    verticalAlignment: go.Spot.Center,
                  }
                )
              )
            );

            nodeContextMenu.adornedObject = node;
            nodeContextMenu.mouseLeave = function () {
              node.removeAdornment("ContextMenuOver");
            };
            node.addAdornment("ContextMenuOver", nodeContextMenu);

            if (store.getters["planogram/product"]) {
              if (node.data.key === store.getters["planogram/product"].key) {
                myDiagram.model.nodeDataArray.forEach(function (nodeS) {
                  if (node.key === nodeS.key) {
                    myDiagram.model.setDataProperty(
                      nodeS,
                      "color",
                      store.getters["planogram/product"].color
                    );
                  }
                });
              }
            }
          },

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: "bold 14px sans-serif" },
          new go.Binding("text", "key")
        )
      );
      // end Node

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("", myDiagram.nodeTemplate);
      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();

            var diagram = grp.diagram;
            var draggedParts = diagram.selection;

            // Check if the dropped parts contain nodes
            var droppedNodes = draggedParts.filter(function (part) {
              return part instanceof go.Node;
            });

            if (droppedNodes.count > 0) {
              var nodeToRemove = myPaletteSmall.model.findNodeDataForKey(
                droppedNodes.first().data.key
              );
              myPaletteSmall.model.removeNodeData(nodeToRemove);
            }
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );

      // Decide what kinds of Parts can be added to a Group
      myDiagram.commandHandler.memberValidation = function (grp, node) {
        // cannot add Groups to Groups
        if (grp instanceof go.Group && node instanceof go.Group) return false;
        return true;
      };

      // What to do when a drag-drop occurs in the Diagram's background
      myDiagram.mouseDragOver = function (e) {
        if (!AllowTopLevel) {
          // OK to drop a group anywhere or any Node that is a member of a dragged Group
          var tool = e.diagram.toolManager.draggingTool;
          if (
            !tool.draggingParts.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel &&
                  tool.draggingParts.contains(p.containingGroup))
              );
            })
          ) {
            e.diagram.currentCursor = "not-allowed";
          } else {
            e.diagram.currentCursor = "";
          }
        }
      };

      myDiagram.mouseDrop = function (e) {
        if (AllowTopLevel) {
          // When the selection is dropped in the diagram's background,
          // Make sure the selected Parts no longer belong to any Group
          if (
            !e.diagram.commandHandler.addTopLevelParts(
              e.diagram.selection,
              true
            )
          ) {
            e.diagram.currentTool.doCancel();
          }
        } else {
          // Disallow dropping any regular nodes onto the background, but allow dropping "racks",
          // Including any selected member nodes
          if (
            !e.diagram.selection.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel && p.containingGroup.isSelected)
              );
            })
          ) {
            e.diagram.currentTool.doCancel();
          }
        }
      };

      // Create array with products to create nodes.
      var products = [];
      /* var randomColors = [];

      // Make a random hsl color.
      function makeColor(colorNum, colors) {
        if (colors < 1) colors = 1;
        return (colorNum * (360 / colors)) % 360;
      }

      for (let i = 0; i <= this.category.products.length; i++) {
        var randomColor =
          "hsl( " +
          makeColor(i, this.category.products.length) +
          ", 100%, 50% )";
        randomColors.push(randomColor);
      }*/

      this.newCategory.products.map((product) => {
        var newProduct = {
          key: product.SKU,
          name: product.description,
          color: product.color,
          size: "150 50",
          category: "normal",
          depth: product.depth,
          width: product.width,
        };

        myDiagram.model.nodeDataArray.forEach(function (node) {
          if (newProduct.key === node.key) {
            myDiagram.model.setDataProperty(node, "color", newProduct.color);
          }
        });

        products.push(newProduct);
      });

      // Compare function to sort alphabetically the categories.
      function compare(a, b) {
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      }

      // Sort categories
      products.sort(compare);

      myPaletteSmall = $(go.Palette, this.category.code + "myPaletteSmall", {
        nodeTemplateMap: myDiagram.nodeTemplateMap,
        groupTemplate: myDiagram.groupTemplate,
      });

      // Specify the contents of the Palette
      myPaletteSmall.model = new go.GraphLinksModel(products);

      let ShelvesPalette = $(
        go.Palette,
        this.category.code + "ShelvesPalette",
        {
          nodeTemplateMap: myDiagram.nodeTemplateMap,
          groupTemplate: myDiagram.groupTemplate,
        }
      );

      // Specify the contents of the Palette
      ShelvesPalette.model = new go.GraphLinksModel([
        { key: "Shelf", isGroup: true, size: "100 100", category: "normal" },
      ]);
    },
  },
};
</script>
