<template>
  <v-textarea
    outlined
    v-bind:value="value"
    v-on="on"
    :type="type"
    :inputmode="inputmode"
    :error-messages="errors"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :readonly="readonly"
    hide-details="auto"
    class="a-textarea"
    @click:append="showValue = !showValue"
    @input="$emit('input', $event)"
  ></v-textarea>
</template>

<script>
/**
 * @vue-prop {String} value - Value
 * @vue-prop {Object} on - On
 * @vue-prop {Object} validator - Validator
 * @vue-prop {String} type - Type
 * @vue-prop {String} label - Label
 *  * @vue-prop {String} placeholder - Placeholder
 * @vue-prop {String} hint - Hint
 * @vue-prop {Boolean} readonly - Read only
 * @vue-computed {String} errors
 */

export default {
  name: "a-text-field",

  data() {
    return {
      showValue: false,
    };
  },

  props: {
    value: String,
    on: Object,
    validator: Object,
    type: String,
    label: String,
    inputmode: String,
    placeholder: String,
    hint: String,
    readonly: Boolean,
  },

  computed: {
    errors() {
      const errors = [];

      if (!this.validator || !this.validator.$dirty) return errors;

      typeof this.validator.required !== "undefined" &&
        !this.validator.required &&
        errors.push(this.$t("errors.fields.REQUIRED_FIELD"));

      return errors;
    },
  },
};
</script>
